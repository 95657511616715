<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>

      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-lg-4 col-xl-3 mb-3">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>
      <div class="col-lg-4 col-xl-3 mb-3">
        <label>Media Owner</label>

        <media-owner-select v-model="filters.mediaOwner" prependAll/>
      </div>
      <div class="col-lg-4 col-xl-3 mb-3">
        <label>Role</label>

        <c-input
          as="multiselect"
          v-model="selectedRole"
          placeholder
          :options="roleOptions"
          :preselect-first="true"
          :show-labels="false"
          :allow-empty="false"
          label="label"
          track-by="value"
        />
      </div>
      <div class="col-lg-4 col-xl-3 mb-3">
        <div class="mb-3">
          <label>Account Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          />
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    MediaOwnerSelect
  },

  data () {
    return {
      filters: {
        search: null,
        status: 'all',
        role: 'all',
        mediaOwner: null,
        sort: 'name',
        order: 'asc'
      },
      roleOptions: [
        { value: null, label: 'All' },
        { value: 'admin', label: 'Administrator' },
        { value: 'user', label: 'Media Owner' },
      ],
      statusOptions: [
        { name: 'All', value: null },
        { name: 'Enabled', value: 'enabled' },
        { name: 'Disabled', value: 'disabled' },
        { name: 'Pending', value: 'pending' }
      ]
    }
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },

    selectedRole: {
      get () {
        return this.roleOptions.find(({ value }) => value === this.filters.role)
      },

      set ({ value }) {
        this.filters.role = value
      }
    },

    sortBy: {
      get () {
        const { filters, sortByOptions } = this

        return sortByOptions.find(({ order, sort }) => filters.sort === sort && filters.order === order)
      },

      set ({ sort, order }) {
        this.filters.sort = sort
        this.filters.order = order
      }
    }
  },

  methods: {
    updateSearch: debounce(function (value) {
      this.filters.search = value
    }, 500)
  }
}
</script>
