<template>
  <fragment>
    <app-header title="My Account" />

    <slot name="nav" />

    <div class="container my-4">
      <div class="row">
        <div class="col-xl-6 d-flex">
          <change-password-card />
        </div>
        <div class="col-xl-6 d-flex">
          <two-factor-card/>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import TwoFactorCard from '@/components/users/TwoFactorCard.vue'
import ChangePasswordCard from '@/components/users/ChangePasswordCard'

export default {
  components: {
    TwoFactorCard,
    ChangePasswordCard
  }
}
</script>
