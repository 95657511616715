<template>
  <div class="card w-100">
    <div class="card-body">
      <h2>2-Factor Authentication</h2>

      <p class="text-info"><i class="far fa-info-circle"></i> Using a second authentication method helps keep you account secure.</p>

      <div class="mb-4">
        <p class="label">Use 2-factor when logging in?</p>

        <c-radio
          name="2fa-opt-in"
          id="2fa-yes"
          inline
          v-model="stateTwoFactorRequired"
          :value="true"
        >
          Yes
        </c-radio>

        <c-radio
          name="2fa-opt-in"
          id="2fa-no"
          inline
          v-model="stateTwoFactorRequired"
          :value="false"
        >
          No
        </c-radio>
      </div>
      <div class="mb-4" v-if="user.two_factor_required">
        <p class="label">Preferred method for 2-factor authentication.</p>

        <c-radio
          inline
          name="2fa-method-preferred"
          id="2fa-method-email"
          v-model="stateTwoFactorMethodPreferred"
          value="email"
        >
          Email
        </c-radio>

        <c-radio
          inline
          name="2fa-method-preferred"
          id="2fa-method-app"
          v-model="stateTwoFactorMethodPreferred"
          value="app"
        >
          Authenticator App
        </c-radio>

        <p
          v-if="user.two_factor_method_enabled === 'app' && user.id === $user.id"
          class="text-muted small"
        >
          <strong>Note:</strong> If you need to add your app authentication key to a different device please click
          <a
            class="text-decoration-underline"
            href=""
            @click.prevent="invalidate"
          >here</a>
          to setup a new device and invalidate your existing app token.
        </p>
        <p class="small text-muted">

        </p>
      </div>

      <c-button variant="primary" @click="save">Save Changes</c-button>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/users'
import { toast } from '@chameleon/chameleon-vue'

export default {
  mixins: [stateMapper],

  computed: {
    user: get('users/user')
  },

  methods: {
    authorize: call('auth/authorize'),
    invalidateTwoFactorAppToken: call('auth/invalidateTwoFactorAppToken'),
    updateTwoFactor: call('users/updateTwoFactor'),

    async save () {
      await this.updateTwoFactor({
        id: this.user.id,
        payload: {
          two_factor_required: this.user.two_factor_required,
          two_factor_method_preferred: this.user.two_factor_method_preferred
        }
      })

      toast('Successfully updated 2-factor authentication', 'success')

      await this.authorize()

      if (this.user.two_factor_required && this.user.two_factor_method_preferred !== this.user.two_factor_method_enabled) {
        switch (this.user.two_factor_method_preferred) {
          case 'app':
            return this.$router.replace({ name: 'two-factor.setup.app' })
          case 'email':
            return this.$router.replace({ name: 'two-factor.setup.email' })
        }
      }
    },

    async invalidate () {
      await this.invalidateTwoFactorAppToken()
      this.$router.replace({ name: 'two-factor.setup.app' })
    }
  }
}
</script>
