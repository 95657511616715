<template>
  <div>
    <app-header title="Add User"/>

    <validation-observer tag="form" class="container container-max-md" @submit.native.prevent ref="validation">
      <h1>Add User</h1>
      <form class="card" @submit.prevent>
        <div class="card-body card-body-lg">
          <h2>Account Details</h2>

          <p>When adding a user they will be sent an email to finish setting up their account and set a password. The user will have system access based on the default role set below.</p>

          <validation-provider tag="div" class="mb-3" name="role" rules="required" >
            <label>User Role</label>
            <role-select v-model="stateRole"/>
            <c-help-block />
          </validation-provider>

          <hr>

          <template v-if="stateRole">
            <validation-provider
              tag="div"
              class="mb-3"
              name="media owner"
              vid="media_owner_id"
              rules="required"
              v-if="user.role && user.role.name === 'user'"
            >
              <label>Media Owner</label>
              <media-owner-select v-model="stateMediaOwnerId"/>
              <c-help-block />
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="first name" rules="required" >
              <label>First Name</label>
              <c-input type="text" v-model="stateFirstName" />
              <c-help-block />
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="last name" rules="" >
              <label>Last Name</label>
              <c-input type="text" v-model="stateLastName" />
            </validation-provider>

            <validation-provider tag="div" class="mb-3" name="email" rules="required|email" >
              <label>Email Address</label>
              <c-input type="text" v-model="stateEmail" />
              <c-help-block />
            </validation-provider>

            <div class="mb-3">
              <label>Mobile Number</label>
              <c-input type="text" placeholder="Optional" v-model="stateMobile" />
            </div>

            <div class="d-flex mt-4">
              <c-button type="submit" variant="primary" @click="submit" validate class="ms-auto" >
                Add User
              </c-button>
            </div>
          </template>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { stateMapper, helperMethods, helperComputed } from '@/vuex/modules/users'
import { toast } from '@chameleon/chameleon-vue'
import RoleSelect from '@/components/forms/SelectRole'
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'

export default {
  mixins: [stateMapper],

  components: {
    RoleSelect,
    MediaOwnerSelect
  },

  computed: helperComputed,

  created () {
    this.resetUser()
  },

  beforeDestroy () {
    this.resetUser()
  },

  methods: {
    ...helperMethods,

    async submit () {
      try {
        const payload = {
          ...this.user,
          role: this.user.role.name
        }
        await this.storeUser(payload)
        toast('Saved successfully', 'success')
        this.$router.push({ name: 'users' })
      } catch (error) {
        if (error.response && error.response.status !== 422) {
          toast('something went wrong creating a user, please contact support', 'danger')
          return
        }

        throw error
      }
    }
  }
}
</script>
