<template>
  <multiselect
    v-model="roleSelected"
    preselect-first
    track-by="name"
    :show-labels="false"
    :allow-empty="false"
    :options="roleOptions"
    label="title"
  />
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  props: {
    role: Object
  },

  model: {
    prop: 'role'
  },

  computed: {
    roles: get('roles/roles'),

    roleSelected: {
      get () {
        return this.role
      },

      set (role) {
        this.$emit('input', role)
      }
    },

    roleOptions () {
      return this.roles
      // return toArray(orderBy(this.roles, ['role', function (role) {
      //   return role.abilities.length
      // }], ['asc', 'asc']))
    }
  },

  async created () {
    if (this.roles.length === 0) {
      await this.getRoles()
    }
  },

  methods: {
    getRoles: call('roles/getRoles')
  }
}
</script>
