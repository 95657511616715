<template>
  <fragment>
    <app-header title="My Account" />

    <slot name="nav" />

    <div class="container container-max-sm">
      <validation-observer tag="form" class="card" @submit.prevent ref="validation">
        <div class="card-body card-body-lg">
          <h2>Account Details</h2>

          <validation-provider
            tag="div"
            class="mb-3"
            name="email"
            rules="required"
          >
            <label>First Name</label>
            <c-input type="text" v-model="first_name" />
          </validation-provider>

          <div class="mb-3">
            <label>Last Name</label>
            <c-input type="text" v-model="last_name" />
          </div>

          <validation-provider
            tag="div"
            class="mb-3"
            name="email"
            rules="required|email"
          >
            <label>Email Address</label>
            <c-input type="text" v-model="email" />
            <c-help-block />
            <p class="text-info mt-1" v-if="user.new_email">
              Email change pending for {{ user.new_email }}
            </p>
          </validation-provider>

          <div class="mb-3">
            <label>Mobile Number</label>
            <c-input type="text" placeholder="Optional" v-model="mobile" />
          </div>

          <div class="row mt-2">
            <div class="col text-end">
              <c-button type="submit" variant="primary" @click="save" validate>
                Save
              </c-button>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'

export default {

  data () {
    return {
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
    }
  },

  created () {
    this.first_name = this.user.first_name
    this.last_name = this.user.last_name
    this.email = this.user.email
    this.mobile = this.user.mobile
  },

  computed: {
    user: get('auth/user')
  },

  methods: {
    updateUser: call('auth/updateUser'),

    async save () {
      await this.updateUser({
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        mobile: this.mobile
      })

      toast('Account details saved.', 'success')
    }
  }
}
</script>
