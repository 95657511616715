<template>
  <div class="container container-max-sm">
    <validation-observer slim>
      <account-details-card />
    </validation-observer>
  </div>
</template>

<script>
import AccountDetailsCard from '@/components/users/AccountDetailsCard'
import { get } from 'vuex-pathify'

export default {
  components: {
    AccountDetailsCard
  },

  computed: {
    ...get('users', ['user'])
  },
}
</script>
