<template>
  <fragment>
    <app-header title="User Management">
      <template #actions>
        <router-link
          class="btn btn-primary mt-2 mt-xl-0"
          :to="{ name: 'users.add' }"
        >
          <i class="far fa-plus"></i> User
        </router-link>
      </template>
    </app-header>

    <div class="container container-fluid mt-4 mb-4">
      <users-list-filters v-model="params" />

      <c-data-list :items="users" @load="load">
        <template #columns>
          <div class="col-xl-2">Name</div>
          <div class="col-xl-3">Email Address</div>
          <div class="col-xl-3">Media Owner</div>
          <div class="col-xl-2">Role</div>
          <div class="col-xl-2">Status</div>
        </template>

        <template #default="{ item }">
          <users-list-item :user="item" />
        </template>
      </c-data-list>
    </div>
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { compareQueryParams } from '@codium/codium-vue'
import UsersListItem from './UsersListItem'
import UsersListFilters from './UsersListFilters'

export default {
  components: {
    UsersListItem,
    UsersListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getUsers({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getUsers({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
