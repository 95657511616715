<template>
  <router-view>
    <template #nav>
      <c-horizontal-nav>
        <c-horizontal-nav-link :to="{ name: 'my.account' }" exact>
          Account
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'my.security' }" exact>
          Account Security
        </c-horizontal-nav-link>
      </c-horizontal-nav>
    </template>
  </router-view>
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  computed: {
    user: get('auth/user')
  },

  async created () {
    await this.getUser(this.user.id)
  },

  methods: {
    getUser: call('users/getUser')
  }
}
</script>
