<template>
  <fragment>
    <app-header title="User Management" />

    <c-horizontal-nav>
      <c-horizontal-nav-link :to="{ name: 'users.single' }" exact>
        Account
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'users.single.security' }" exact>
        Account Security
      </c-horizontal-nav-link>
    </c-horizontal-nav>

    <router-view v-if="user.id" />
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/users'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },

  watch: {
    $route: {
      async handler () {
        await this.resetUser()

        await this.getUser(this.userId || this.$user.id)
      },
      immediate: true
    }
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods
  }
}
</script>
