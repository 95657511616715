var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validation",staticClass:"card w-100",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"card-body card-body-lg d-flex flex-column"},[_c('h2',{staticClass:"card-title"},[_vm._v("Change Password")]),_c('validation-provider',{staticClass:"mb-4",attrs:{"tag":"div","vid":"current_password","name":"current password","rules":"required"}},[_c('label',[_vm._v("Current Password")]),_c('c-input',{attrs:{"type":"password"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('c-help-block')],1),_c('validation-provider',{ref:"password",staticClass:"mb-4",attrs:{"tag":"div","mode":"aggressive","bails":false,"name":"password","rules":{
        required: true,
        min: { length: 8 },
        contains_special_character: true,
        contains_number: true,
        is: _vm.passwordConfirmation,
      }}},[_c('label',[_vm._v("Password")]),_c('c-input',{attrs:{"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('c-help-block')],1),_c('validation-provider',{staticClass:"mb-4",attrs:{"tag":"div","name":"repeat password","vid":"password_confirmation","rules":{
        required: true,
        min: { length: 8 },
        contains_special_character: true,
        contains_number: true,
      },"mode":"aggressive"}},[_c('label',[_vm._v("Repeat Password")]),_c('c-input',{attrs:{"type":"password"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('c-help-block')],1),_c('password-strength-bar',_vm._b({staticClass:"mb-4"},'password-strength-bar',{ password: _vm.password, label: 'Password Strength' },false)),_c('div',{staticClass:"text-end mt-auto"},[_c('c-button',{attrs:{"type":"submit","variant":"primary","validate":""},on:{"click":_vm.save}},[_vm._v(" Change Password ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }