<template>
  <div class="container container-max-md">
    <two-factor-card/>
  </div>
</template>

<script>
import TwoFactorCard from '@/components/users/TwoFactorCard.vue'

export default {
  components: {
    TwoFactorCard
  }
}
</script>
