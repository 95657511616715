<template>
  <router-link tag="a" :to="{ name: 'users.single', params: { userId: user.id } }">
    <div class="row">
      <div class="col-12 col-xl-2 text-semibold">
        <div class="row">
          <div class="col d-flex align-items-center">
            <p class="strong text-truncate">{{ user.name }}</p>
          </div>
        </div><!-- /.row -->
      </div>
      <div class="col-12 col-md-5 col-xl-3">
        <p class="text-truncate">
          <span class="data-label d-lg-none">Email</span>
          {{ user.email }}
        </p>
      </div>
      <div class="col-4 col-md-3 col-xl-3">
        <p class="text-truncate">
          <span class="data-label d-lg-none">Media Owner</span>
          {{ user.media_owner ? user.media_owner.name : '-' }}
        </p>
      </div>
      <div class="col-4 col-md-2 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Role</span>
          {{ user.role }}
        </p>
      </div>
      <div class="col-4 col-md-2 col-xl-2">
        <span class="status status-success" v-if="user.status === 'enabled'">Enabled</span>
        <span class="status status-danger" v-if="user.status === 'disabled'">Disabled</span>
        <span class="status status-info" v-if="user.status === 'pending'">Pending</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  }
}
</script>
